import logo from './logo.svg';
import './style.scss';
import React from 'react'


function Header() {
    return(
        <>
          <h1 id="title">Jump Into Nutrition</h1>
        </>
    )
}

function Footer() {
    return(
        <div id="footer">
            <h6>Powered by Tetrachrome</h6>
        </div>
    )
}

function App() {
  return (
      <>
        <Header/>
        <Footer/>
      </>
  );
}

export default App;


